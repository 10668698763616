import {
     GET_NAMES,
    EDIT_NAME, DELETE_NAME, ADD_NAME, GET_NAME
   } from './types'
   
   import axios from 'axios'

import {returnErrors} from './errorActions'

   
   
   export const getName = (id) => (dispatch) => {
       axios.get(`${process.env.REACT_APP_URL}/name/${id}`)
       .then(res => {
           dispatch({
               type: GET_NAME,
               payload: res.data
           })
       })
   }
   
   export const getNames = () => (dispatch) => {
       axios.get(`${process.env.REACT_APP_URL}/names`)
       .then(res => {
           dispatch({
               type: GET_NAMES,
               payload: res.data
           })
       })
   }
   
   export const editName = (id, body) => (dispatch) => {
       axios.put(`${process.env.REACT_APP_URL}/name/${id}`, body)
       .then(res => {
           dispatch({
               type: EDIT_NAME,
               payload: res.data
           })
       })
       .catch(err => {
     
        dispatch(
          returnErrors(err.response.data, err.response.status, 'add name failed')
        );
       })
   }
   
   
   export const deleteName = (id, type) => (dispatch) => {
       axios.delete(`${process.env.REACT_APP_URL}/name/${id}/${type}`)
       .then(res => {
           dispatch({
               type: DELETE_NAME,
               payload: res.data
           })
       })
   }
   
   export const addName = (body) => (dispatch) => {
       axios.post(`${process.env.REACT_APP_URL}/name`, body)
       .then(res => {
           dispatch({
               type: ADD_NAME,
               payload: res.data
           })
       })

       .catch(err => {
     
        dispatch(
          returnErrors(err.response.data, err.response.status, 'add name failed')
        );
       })
   }
   