import {
 GET_PACKAGE, GET_PACKAGES, DELETE_PACKAGE, EDIT_PACKAGE,
 ADD_PACKAGE
} from './types'

import axios from 'axios'


export const getPackage = (id) => (dispatch) => {
    axios.get(`${process.env.REACT_APP_URL}/package/${id}`)
    .then(res => {
        dispatch({
            type: GET_PACKAGE,
            payload: res.data
        })
    })
}

export const getPackages = () => (dispatch) => {
    axios.get(`${process.env.REACT_APP_URL}/packages`)
    .then(res => {
        dispatch({
            type: GET_PACKAGES,
            payload: res.data
        })
    })
}

export const editPackage = (id, body) => (dispatch) => {
    axios.put(`${process.env.REACT_APP_URL}/package/${id}`, body)
    .then(res => {
        dispatch({
            type: EDIT_PACKAGE,
            payload: res.data
        })
    })
}


export const deletePackage = (id) => (dispatch) => {
    axios.delete(`${process.env.REACT_APP_URL}/package/${id}`)
    .then(res => {
        dispatch({
            type: DELETE_PACKAGE,
            payload: res.data
        })
    })
}

export const addPackage = (body) => (dispatch) => {
    axios.post(`${process.env.REACT_APP_URL}/package`, body)
    .then(res => {
        dispatch({
            type: ADD_PACKAGE,
            payload: res.data
        })
    })
}

