import {
    GET_FRAMES,
    DELETE_FRAME,
    ADD_FRAME
   } from './types'
   
   import axios from 'axios'
   

   export const getFrames = () => (dispatch) => {
       axios.get(`${process.env.REACT_APP_URL}/frames`)
       .then(res => {
           dispatch({
               type: GET_FRAMES,
               payload: res.data
           })
       })
   }
   

   export const deleteFrame = (id) => (dispatch) => {
       axios.delete(`${process.env.REACT_APP_URL}/frame/${id}`)
       .then(res => {
           dispatch({
               type: DELETE_FRAME,
               payload: res.data
           })
       })
   }
   
   export const addFrame = (body) => (dispatch) => {
       axios.post(`${process.env.REACT_APP_URL}/frame`, body)
       .then(res => {
           dispatch({
               type: ADD_FRAME,
               payload: res.data
           })
       })
   }
   
   