import {
    GET_COVERS,
    DELETE_COVER,
    ADD_COVER
   } from './types'
   
   import axios from 'axios'
   

   export const getCovers = () => (dispatch) => {
       axios.get(`${process.env.REACT_APP_URL}/covers`)
       .then(res => {
           dispatch({
               type: GET_COVERS,
               payload: res.data
           })
       })
   }
   

   export const deleteCover = (id) => (dispatch) => {
       axios.delete(`${process.env.REACT_APP_URL}/cover/${id}`)
       .then(res => {
           dispatch({
               type: DELETE_COVER,
               payload: res.data
           })
       })
   }
   
   export const addCover = (body) => (dispatch) => {
       axios.post(`${process.env.REACT_APP_URL}/cover`, body)
       .then(res => {
           dispatch({
               type: ADD_COVER,
               payload: res.data
           })
       })
   }
   
   